<template>
	<v-container
		v-if="$can('create', 'CaseStudy') || $can('update', 'CaseStudy')"
		fluid
	>
		<v-card class="mb-0" v-if="!loading">
			<v-form>
				<v-row class="mx-0 pa-8 pb-0">
					<v-col cols="12" class="pa-0">
						<p class="ma-0 mb-3 label">Before/After Image :</p>
						<FileUploader
							:initFiles="caseImage ? [caseImage] : []"
							@errorOccured="dropzoneError"
							:purpose="`before/after image`"
							:maxFiles="1"
							@changed="fileUploaderChanged"
						/>
						<span class="error-msg" v-if="!caseImageId">
							{{ errors.image }}
						</span>
					</v-col>
					<v-col cols="12" class="pa-0 pr-6">
						<p class="mb-3">Title for the Case study* :</p>
						<v-text-field
							v-model="title"
							placeholder="Enter the Title here"
							dense
							outlined
							@input="$v.title.$touch()"
							@blur="$v.title.$touch()"
							:error-messages="titleErrors()"
						></v-text-field>
					</v-col>
					<v-col cols="6" class="pa-0 pr-6 mb-2">
						<p class="ma-0 mb-3 label">Patient's Name :</p>
						<v-text-field
							placeholder="Enter full name of the Patient"
							solo
							flat
							dense
							v-model="patientName"
							outlined
							@input="$v.patientName.$touch()"
							@blur="$v.patientName.$touch()"
							:error-messages="patientNameErrors()"
						></v-text-field>
					</v-col>
					<v-col cols="3" class="pa-0 pr-6 mb-2">
						<p class="ma-0 mb-3 label">Patient's Age :</p>
						<v-select
							class="pa-0"
							:items="yearsArray"
							placeholder="Select Age"
							v-model="patientAge"
							@focus="dropdown()"
							@input="$v.patientAge.$touch()"
							@blur="$v.patientAge.$touch()"
							:error-messages="patientAgeErrors()"
						></v-select>
					</v-col>
					<v-col cols="3" class="pa-0 mb-2">
						<p class="ma-0 mb-3 label">Patient's Gender :</p>
						<v-radio-group
							v-model="patientGender"
							:mandatory="false"
							class="mt-3"
						>
							<div class="d-flex align-start">
								<v-radio
									label="Female"
									value="female"
									class="mr-8 ma-0 radio-btn"
								></v-radio>
								<v-radio
									label="Male"
									value="male"
									class="ma-0 radio-btn"
								></v-radio>
							</div>
						</v-radio-group>
					</v-col>
					<v-col cols="6" class="pa-0 pr-6 mb-8">
						<p class="mb-3">Symptoms* :</p>
						<v-text-field
							v-model="symptom"
							@keyup.enter="updateSymptoms(symptom)"
							placeholder="Enter the Symptoms here"
							dense
							@input="$v.symptom.$touch()"
							@blur="$v.symptom.$touch()"
							:error-messages="symptomsError()"
						></v-text-field>
						<v-chip
							v-for="symptom in symptoms"
							:key="symptom.uuid"
							class="mr-2 chip mb-2"
							close
							@click:close="removeSymptom(symptom.uuid)"
						>
							{{ symptom.name }}
						</v-chip>
					</v-col>
					<v-col cols="6" class="pa-0 mb-8">
						<p class="mb-3">Tests Performed* :</p>
						<v-text-field
							v-model="testPerformed"
							@keyup.enter="updateTestsPerformed()"
							placeholder="Enter the Tests Performed here"
							dense
							@input="$v.testPerformed.$touch()"
							@blur="$v.testPerformed.$touch()"
							:error-messages="testsPerformedErros()"
						></v-text-field>
						<v-chip
							v-for="testPerformed in testsPerformed"
							:key="testPerformed.uuid"
							class="mr-2 chip mb-2"
							close
							@click:close="
								removeTestPerformed(testPerformed.uuid)
							"
						>
							{{ testPerformed.name }}
						</v-chip>
					</v-col>
					<v-col cols="6" class="pa-0 pr-6 mb-8">
						<p class="mb-3">Diagnosis* :</p>
						<v-text-field
							v-model="diagnos"
							@keyup.enter="updateDiagnosis()"
							placeholder="Enter the Diagnosis here"
							dense
							@input="$v.diagnos.$touch()"
							@blur="$v.diagnos.$touch()"
							:error-messages="diagnosisErros()"
						></v-text-field>
						<v-chip
							v-for="diagnosis in diagnosis"
							:key="diagnosis.uuid"
							class="mr-2 chip mb-2"
							close
							@click:close="removeDiagnos(diagnosis.uuid)"
						>
							{{ diagnosis.name }}
						</v-chip>
					</v-col>
					<v-col cols="6" class="pa-0 pr-6 mb-8">
						<p class="mb-3">Services Used:</p>
						<v-text-field
							v-model="service"
							@keyup.enter="updateService()"
							placeholder="Enter the Diagnosis here"
							dense
							@input="$v.service.$touch()"
							@blur="$v.service.$touch()"
							:error-messages="servicesErrors()"
						></v-text-field>
						<v-chip
							v-for="service in services"
							:key="service.uuid"
							class="mr-2 chip mb-2"
							close
							@click:close="removeService(service.uuid)"
						>
							{{ service.name }}
						</v-chip>
					</v-col>

					<v-col cols="6" class="pa-0 mb-4">
						<p class="ma-0 mb-3 label">Tags:</p>
						<Search
							:initialData="relatedServices"
							:url="`/${$store.state.app.projectId}/services/search`"
							@search-result="updateRelatedService($event)"
						></Search>
					</v-col>
					<v-col cols="12" class="pa-0 mb-8">
						<p class="ma-0 label mb-3">
							Detailed Description of the Case* :
						</p>
						<div id="ckcontent">
							<Editor
								:initData="detailedDescription"
								:purpose="`detailed description of the case`"
								@changed="detailedDescriptionUpdate"
							></Editor>
							<span
								class="error-msg"
								v-for="(
									error, ind
								) in detailedDescriptionErros()"
								:key="ind"
							>
								{{ error }}
							</span>
						</div>
					</v-col>
					<v-col cols="12" class="pa-0 mb-8">
						<p class="ma-0 label mb-3">
							Post Treatment Guidelines :
						</p>
						<div id="ckcontent">
							<Editor
								:initData="guidelines"
								:purpose="`post treatment guidelines`"
								@changed="guidelinesUpdate"
							></Editor>
							<span
								class="error-msg"
								v-for="(error, ind) in guidelinesErrors()"
								:key="ind"
							>
								{{ error }}
							</span>
						</div>
					</v-col>
					<v-col cols="12" class="pa-0">
						<p class="mb-3">Patient's FeedBack :</p>
						<v-textarea
							class="pa-0"
							v-model="feedback"
							name="patientFeedback"
							placeholder="Enter the patient''s feedback"
							outlined
							no-resize
							auto-grow
							:error-messages="feedbackErrors()"
							@input="$v.feedback.$touch()"
							@blur="$v.feedback.$touch()"
						></v-textarea>
					</v-col>
				</v-row>
			</v-form>

			<v-divider></v-divider>

			<v-card-actions class="pa-6 card-actions">
				<UnoSaveButton
					@click.native="validateData()"
					@primary-button-click="caseStudyListing()"
					primary-button-text="Move To Case Study Listing"
					secondary-button-text="Continue Editing"
					:alert="alert"
					:saving="isSaving"
				></UnoSaveButton>

				<UnoPublishButton
					v-if="isEdit && $can('publish', 'CaseStudy')"
					:is-published="isPublished"
					@click.native="toggleStatus()"
				></UnoPublishButton>

				<v-spacer></v-spacer>
				<UnoDeleteButton
					v-if="isEdit && $can('delete', 'CaseStudy')"
					@confirm="deleteCaseStudy()"
				></UnoDeleteButton>
			</v-card-actions>
		</v-card>
	</v-container>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
import { required, minLength, alpha, between } from 'vuelidate/lib/validators'

import Search from '@/components/Search'
// ACTION BUTTONS
import FileUploader from '@/components/FileUploader'
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
import Editor from '@/components/CkEditor'

export default {
	components: {
		UnoSaveButton,
		UnoDeleteButton,
		UnoPublishButton,
		FileUploader,
		Editor,
		Search,
	},
	props: {
		id: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			title: '',
			yearsArray: [],
			patientAge: null,
			patientName: '',
			patientGender: '',
			symptom: '',
			symptoms: [],
			diagnos: '',
			diagnosis: [],
			testPerformed: '',
			testsPerformed: [],
			service: null,
			services: [],
			relatedServices: [],
			detailedDescription: '',
			guidelines: '',
			caseImage: null,
			caseImageId: null,
			feedback: '',
			isEdit: false,
			isSaving: false,
			loading: false,
			caseStudyId: null,
			alert: {
				show: null,
				message: null,
				error: false,
			},
			errors: {
				image: '',
				title: '',
				symptoms: '',
				testsPerformed: '',
				diagnosis: '',
				services: '',
				detailedDescription: '',
			},
			isPublished: false,
		}
	},
	validations: {
		title: {
			required,
			minLength: minLength(5),
		},
		patientName: {
			required,
			alpha,
			minLength: minLength(2),
		},
		patientAge: {
			between: between(0, 100),
		},
		symptoms: {
			minLength: minLength(1),
		},
		symptom: {
			minLength: minLength(3),
		},
		diagnosis: {
			minLength: minLength(1),
		},
		diagnos: {
			minLength: minLength(3),
		},
		services: {
			minLength: minLength(1),
		},
		service: {
			minLength: minLength(3),
		},
		testsPerformed: {
			minLength: minLength(1),
		},
		testPerformed: {
			minLength: minLength(3),
		},
		// services:{

		// 	minLength:minLength(15)
		// },
		detailedDescription: {
			required,
			minLength: minLength(17),
		},
		guidelines: {
			minLength: minLength(17),
		},
		feedback: {
			minLength: minLength(10),
		},
	},
	created() {
		if (this.id) {
			this.isEdit = true
			this.caseStudyId = this.id
			this.getCaseStudy()
		} else {
			this.loading = false
		}
	},
	watch: {
		$route: 'getCaseStudy',
		title() {
			this.errors.title = ''
		},
		symptom() {
			this.errors.symptoms = ''
		},
		testPerformed() {
			this.errors.testsPerformed = ''
		},
		services() {
			this.errors.services
		},
		diagnos() {
			this.errors.diagnosis = ''
		},
		detailedDescription() {
			this.errors.detailedDescription = ''
		},
	},
	methods: {
		titleErrors() {
			const errors = []
			if (!this.$v.title.$dirty) return errors

			!this.$v.title.minLength &&
				errors.push('Title must be atleast 5 letters long')
			!this.$v.title.required && errors.push('Title is required.')

			return errors
		},
		patientNameErrors() {
			const errors = []
			if (!this.$v.patientName.$dirty) return errors

			!this.$v.patientName.alpha &&
				errors.push("Patient's Name can't contain numbers")
			!this.$v.patientName.required &&
				errors.push("Patient's name is required.")
			!this.$v.patientName.minLength &&
				errors.push("Patient's name should contain atleast 2 letters.")

			return errors
		},
		patientAgeErrors() {
			const errors = []
			if (!this.$v.patientAge.$dirty) return errors

			!this.$v.patientAge.between &&
				errors.push('Patient age should be between 0-100')
			return errors
		},
		symptomsError() {
			const errors = []

			!this.$v.symptoms.$model.length > 0 &&
				errors.push(
					'There must be atleast one symptom,Symptom must contain atleast 3 letters '
				)
			if (!this.$v.symptom.$dirty) return errors

			!this.$v.symptom.minLength &&
				errors.push('Symptom must contain atleast 3 letters')
			return errors
		},
		testsPerformedErros() {
			const errors = []

			!this.$v.testsPerformed.$model.length > 0 &&
				errors.push(
					'There must be atleast one test performed, Test performed must contain atleast 3 letters '
				)
			if (!this.$v.testPerformed.$dirty) return errors

			!this.$v.testPerformed.minLength &&
				errors.push('Test performed must contain atleast 3 letters')
			return errors
		},
		diagnosisErros() {
			const errors = []

			!this.$v.diagnosis.$model.length > 0 &&
				errors.push(
					'There must be atleast one diagnosis, Diagnos must contain atleast 3 letters '
				)
			if (!this.$v.diagnos.$dirty) return errors

			!this.$v.diagnos.minLength &&
				errors.push('Diagnos performed must contain atleast 3 letters')
			return errors
		},
		servicesErrors() {
			const errors = []

			// !this.$v.services.$model.length > 0 &&
			// 	errors.push(
			// 		'There must be atleast one services, Service must contain atleast 3 letters '
			// 	)
			if (!this.$v.service.$dirty) return errors

			!this.$v.service.minLength &&
				errors.push('Service performed must contain atleast 3 letters')
			return errors
		},
		detailedDescriptionErros() {
			const errors = []
			if (!this.$v.detailedDescription.$dirty) return errors

			!this.$v.detailedDescription.required &&
				errors.push('Detailed description is required')
			!this.$v.detailedDescription.minLength &&
				errors.push(
					'Detailed description should contain atleast 10 letters.'
				)

			return errors
		},
		guidelinesErrors() {
			const errors = []
			if (!this.$v.guidelines.$dirty) return errors
			!this.$v.guidelines.minLength &&
				errors.push('Guidelines should contain atleast 10 letters.')

			return errors
		},
		feedbackErrors() {
			const errors = []
			if (!this.$v.feedback.$dirty) return errors
			!this.$v.feedback.minLength &&
				errors.push('Feedback should contain atleast 10 letters.')

			return errors
		},

		dropdown: function () {
			this.yearsArray = Array.from(Array(100).keys())
		},
		detailedDescriptionUpdate(body) {
			this.$v.detailedDescription.$touch()
			this.detailedDescription = body
		},
		guidelinesUpdate(body) {
			this.$v.guidelines.$touch()
			this.guidelines = body
		},
		updateSymptoms() {
			if (this.symptom.trim().length > 0) {
				let obj = {
					name: this.symptom,
					uuid: uuidv4(),
				}
				let symptomsArray = this.symptoms
				symptomsArray.push(obj)

				this.symptom = ''
			}
		},
		removeSymptom(id) {
			let symptomsArray = this.symptoms
			symptomsArray.every(function (symptom, index) {
				if (symptom.uuid === id) {
					symptomsArray.splice(index, 1)
					return false //break the loop
				} else {
					return true
				}
			})
			this.symptoms = symptomsArray
		},
		updateTestsPerformed() {
			if (this.testPerformed.trim().length > 0) {
				let obj = {
					name: this.testPerformed,
					uuid: uuidv4(),
				}
				let testsPerformedArray = this.testsPerformed
				testsPerformedArray.push(obj)
				this.testPerformed = ''
			}
		},
		removeTestPerformed(id) {
			let testsPerformedArray = this.testsPerformed
			testsPerformedArray.every(function (testPerformed, index) {
				if (testPerformed.uuid === id) {
					testsPerformedArray.splice(index, 1)
					return false //break the loop
				} else {
					return true
				}
			})
			this.testsPerformed = testsPerformedArray
		},
		updateDiagnosis() {
			if (this.diagnos.trim().length > 0) {
				let obj = {
					name: this.diagnos,
					uuid: uuidv4(),
				}
				let diagnosisArray = this.diagnosis
				diagnosisArray.push(obj)
				this.diagnos = ''
			}
		},
		removeDiagnos(id) {
			let diagnosisArray = this.diagnosis
			diagnosisArray.every(function (diagnos, index) {
				if (diagnos.uuid === id) {
					diagnosisArray.splice(index, 1)
					return false //break the loop
				} else {
					return true
				}
			})
			this.diagnosis = diagnosisArray
		},
		updateService() {
			if (this.service.trim().length > 0) {
				let obj = {
					name: this.service,
					uuid: uuidv4(),
				}
				let servicesArray = this.services
				servicesArray.push(obj)
				this.service = ''
			}
		},
		removeService(id) {
			let servicesArray = this.services
			servicesArray.every(function (service, index) {
				if (service.uuid === id) {
					servicesArray.splice(index, 1)
					return false //break the loop
				} else {
					return true
				}
			})
			this.services = servicesArray
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				const data = val[0]
				this.caseImageId = data.id
				this.purpose = 'before/after image'
				this.caseImage = {
					id: this.caseImageId,
					publicURL: data.publicURL,
				}
			} else {
				this.caseImageId = null
				this.caseImage = null
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
		getCaseStudy() {
			if (this.isEdit) {
				this.loading = true
				const projectId = this.$store.state.app.projectId
				this.axios
					.get(`/${projectId}/case-studies/${this.id}`)
					.then((res) => {
						if (res.status === 404) {
							console.log(404)
						}
						const data = res.data.data
						this.setCaseStudyData(data)
						this.loading = false
					})
			}
		},
		setCaseStudyData(caseStudy) {
			this.caseImage = caseStudy.caseImage ? caseStudy.caseImage : null
			this.caseImageId =
				caseStudy.caseImage && caseStudy.caseImage.media
					? caseStudy.caseImage.media
					: null
			this.caseStudyId = caseStudy.id
			this.title = caseStudy.title
			this.slug = caseStudy.slug
			this.isPublished = caseStudy.isPublished
			this.loading = false
			if (
				caseStudy.relatedServices &&
				caseStudy.relatedServices.length > 0 &&
				caseStudy.relatedServices[0].id
			) {
				this.relatedServices = caseStudy.relatedServices
			}
			this.guidelines = caseStudy.guidelines
			this.services = caseStudy.services.map((str) => ({
				name: str,
				uuid: uuidv4(),
			}))
			this.symptoms = caseStudy.symptoms.map((str) => ({
				name: str,
				uuid: uuidv4(),
			}))
			this.diagnosis = caseStudy.diagnosis.map((str) => ({
				name: str,
				uuid: uuidv4(),
			}))
			this.testsPerformed = caseStudy.testsPerformed.map((str) => ({
				name: str,
				uuid: uuidv4(),
			}))
			this.feedback = caseStudy.feedback
			this.detailedDescription = caseStudy.detailedDescription
			this.patientName = caseStudy.patientName
			this.patientAge = caseStudy.patientAge
			this.patientGender = caseStudy.patientGender
		},
		validateData() {
			let isValid = true
			if (this.title === '') {
				this.errors.title = 'Title field should not be empty.'
				isValid = false
			} else {
				this.errors.title = ''
			}
			if (!this.caseImageId) {
				isValid = false
				this.errors.image = 'Image is required'
			} else {
				this.errors.image = ''
			}
			if (this.symptoms.length === 0) {
				this.errors.symptoms = 'Add atleast one symptom'
				isValid = false
			} else {
				this.errors.symptoms = ''
			}
			if (this.testsPerformed.length === 0) {
				this.errors.testsPerformed =
					'Add atleast one test that is performed'
				isValid = false
			} else {
				this.errors.testsPerformed = ''
			}
			if (this.diagnosis.length === 0) {
				this.errors.diagnosis = 'This field is required'
				isValid = false
			} else {
				this.errors.diagnosis = ''
			}
			if (this.detailedDescription === '') {
				this.errors.detailedDescription =
					'Detailed Description of the case is required'
				isValid = false
			} else {
				this.errors.detailedDescription = ''
			}
			// if (this.services.length === 0) {
			// 	this.errors.services = 'Add atleast one treatment'
			// 	isValid = false
			// } else {
			// 	this.errors.services = ''
			// }
			if (isValid) {
				this.saveData()
			}
		},
		saveData() {
			this.isSaving = true
			const isEdit = this.isEdit
			const projectId = this.$store.state.app.projectId

			const payload = {
				title: this.title,
				patientName: this.patientName ? this.patientName : null,
				patientAge: this.patientAge ? this.patientAge : null,
				patientGender: this.patientGender ? this.patientGender : null,
				symptoms: this.symptoms.map((symptom) => {
					return symptom.name
				}),
				testsPerformed: this.testsPerformed.map((testPerformed) => {
					return testPerformed.name
				}),
				diagnosis: this.diagnosis.map((diagnos) => {
					return diagnos.name
				}),
				relatedServices: this.relatedServices.map((obj) => {
					return obj['id'] ? obj['id'] : obj
				}),

				services: this.services.map((service) => {
					return service.name
				}),
				detailedDescription: this.detailedDescription,
				guidelines: this.guidelines ? this.guidelines : null,

				feedback: this.feedback ? this.feedback : null,
				caseImage: this.caseImageId ? this.caseImageId : null,
			}
			this.axios({
				method: isEdit ? 'put' : 'post',
				url: isEdit
					? `/${projectId}/case-studies/${this.caseStudyId}`
					: `/${projectId}/case-studies`,
				data: payload,
			})
				.then((res) => {
					this.isSaving = false
					if (res.status === 404) {
						console.log(404)
					}

					if (res.status == 200 && res.data.success) {
						const data = res.data.data
						this.isEdit = true
						this.setCaseStudyData(data)
						this.alert.message = res.data.message
					}
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.isSaving = false
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
		deleteCaseStudy() {
			const projectId = this.$store.state.app.projectId
			this.axios
				.delete(`/${projectId}/case-studies/${this.id}`)
				.then(() =>
					this.$router.push({
						name: 'case-study.list',
					})
				)
		},
		toggleStatus() {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'post',
				url: `/${projectId}/case-studies/toggle-status/${this.caseStudyId}`,
			}).then(() => {
				this.isPublished = !this.isPublished
			})
		},
		caseStudyListing() {
			this.$router.push({
				name: 'case-study.list',
			})
		},
		updateRelatedService(array) {
			this.relatedServices = array
		},
	},
}
</script>
